import React, { useState, useRef } from 'react';
import { Phone, BarChart, Check } from 'lucide-react';

const App = () => {
  const [email, setEmail] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const emailInputRef = useRef<HTMLInputElement | null>(null);

  // Handle form submission (waitlist)
  const handleJoinWaitlist = async () => {
    // Using null checks and type casting
    const emailInput = document.getElementById("emailInput") as HTMLInputElement;
    
    if (!emailInput || !emailInput.value) {
      alert("Please enter a valid email address.");
      return;
    }

    const email = emailInput.value;

    const waitlistData = {
      waitlist_id: 22783,
      referral_link: "https://getwaitlist.com/waitlist/22783",
      heartbeat_uuid: "",
      widget_type: "WIDGET_1",
      email: email,
      answers: [],
    };

    try {
      const response = await fetch("https://api.getwaitlist.com/api/v1/waiter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(waitlistData),
      });

      if (response.ok) {
        alert("Successfully joined the waitlist!");
        // Optionally, clear the email field after successful submission
        emailInput.value = ''; // Clear the input field
      } else {
        alert("There was an error joining the waitlist. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error. Please check your internet connection.");
    }
  };

  // Handle click for bottom button (scroll and focus email input)
  const handleBottomJoinWaitlistClick = () => {
    if (emailInputRef.current) {
      // Scroll to email input section
      emailInputRef.current.scrollIntoView({ behavior: 'smooth' });
      // Focus and highlight email input briefly
      emailInputRef.current.focus();
      setIsFocused(true);
      setTimeout(() => setIsFocused(false), 1000); // Remove focus after 1 second
    }
  };

  const handleTopJoinWaitlistClick = () => {
    if (emailInputRef.current) {
      // Focus and highlight email input briefly
      emailInputRef.current.focus();
      setIsFocused(true);
      setTimeout(() => setIsFocused(false), 1000); // Remove focus after 1 second
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Navigation */}
      <nav className="px-8 py-4 flex justify-between items-center border-b">
        <div className="text-2xl font-bold text-[#5082EB]">Tiaki AI</div>
        <div>
          <button
            onClick={handleTopJoinWaitlistClick}
            className="px-4 py-2 bg-[#5082EB] text-white rounded hover:bg-blue-600 ml-2"
          >
            Join Waitlist
          </button>
        </div>
      </nav>

      {/* Hero Section */}
      <header className="flex-grow container mx-auto px-8 py-16 grid md:grid-cols-2 gap-12 items-center">
        <div>
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            AI-Powered Outbound Calling for Real Estate Agents
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Tiaki automates client communication, helping you connect with more leads and close more deals faster.
          </p>
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
            <input
              ref={emailInputRef}
              id="emailInput"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`px-6 py-3 w-full md:w-72 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5082EB] ${isFocused ? 'border-[#5082EB]' : 'border-gray-300'}`}
            />
            <button
              onClick={handleJoinWaitlist}
              className="px-6 py-3 w-full md:w-auto bg-[#5082EB] text-white rounded-lg hover:bg-blue-600 transition"
            >
              Join Waitlist
            </button>
          </div>
        </div>
        <div className="bg-blue-100 rounded p-8 flex justify-center items-center">
          <div className="relative w-full" style={{ paddingBottom: '100%' }}>
            <iframe
              src="https://www.youtube.com/embed/MvFrCWjRl8c"
              title="Real Estate Call Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full"
            ></iframe>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section className="bg-gray-50 py-16">
        <div className="container mx-auto px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Why Tiaki?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded shadow-md text-center">
              <Phone className="mx-auto mb-4 text-[#5082EB]" size={48} />
              <h3 className="text-xl font-semibold mb-4">Automated Calling</h3>
              <p className="text-gray-600">
                AI-driven calls that qualify leads and schedule appointments without manual intervention.
              </p>
            </div>
            <div className="bg-white p-6 rounded shadow-md text-center">
              <BarChart className="mx-auto mb-4 text-[#5082EB]" size={48} />
              <h3 className="text-xl font-semibold mb-4">Performance Tracking</h3>
              <p className="text-gray-600">
                Real-time analytics to measure call effectiveness and optimize your outreach strategy.
              </p>
            </div>
            <div className="bg-white p-6 rounded shadow-md text-center">
              <Check className="mx-auto mb-4 text-[#5082EB]" size={48} />
              <h3 className="text-xl font-semibold mb-4">Easy Integration</h3>
              <p className="text-gray-600">
                Seamlessly connects with your existing CRM and lead management systems.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-gray-900 text-white py-16 text-center">
        <h2 className="text-4xl font-bold mb-6">Transform Your Real Estate Business</h2>
        <p className="text-xl mb-8">
          Join our waitlist and experience the power of AI-driven client communication.
        </p>
        <button
          onClick={handleBottomJoinWaitlistClick}
          className="px-8 py-4 bg-white text-[#5082EB] rounded hover:bg-blue-50 transition"
        >
          Join Waitlist
        </button>
      </section>
    </div>
  );
};

export default App;
